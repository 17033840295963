.item{
    display: flex;
    align-items: flex-start;
}

.red{
    width: 20px;
    height: 20px;
    background-color: red;
}

.yellow{
    width: 20px;
    height: 20px;
    background-color: yellow;
}