.item {
  align-items: flex-start;
  display: flex;
}

.red {
  width: 20px;
  height: 20px;
  background-color: red;
}

.yellow {
  width: 20px;
  height: 20px;
  background-color: #ff0;
}

/*# sourceMappingURL=index.7d2edf3f.css.map */
